/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Api2ResponseOfString2 } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Integration<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags integration-auth-controller-api-v-2
   * @name GetYandexUserJwtUsingGet
   * @summary getYandexUserJwt
   * @request GET:/api/v2/integration/auth/getYandexUserJwt
   * @secure
   */
  getYandexUserJwtUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfString2, void>({
      path: `/api/v2/integration/auth/getYandexUserJwt`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags integration-auth-controller-api-v-2
   * @name RevokeYandexAccessTokenUsingGet
   * @summary revokeYandexAccessToken
   * @request GET:/api/v2/integration/auth/revokeYandexAccessToken
   * @secure
   */
  revokeYandexAccessTokenUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfString2, void>({
      path: `/api/v2/integration/auth/revokeYandexAccessToken`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags integration-auth-controller-api-v-2
   * @name GetGoogleUserJwtUsingGet
   * @summary getGoogleUserJwt
   * @request GET:/api/v2/integration/auth/getGoogleUserJwt
   * @secure
   */
  getGoogleUserJwtUsingGet = (
    query: {
      /** redirect URI который which used for getting auth code */
      redirectUri: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfString2, void>({
      path: `/api/v2/integration/auth/getGoogleUserJwt`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags integration-auth-controller-api-v-2
   * @name GetAppleUserJwtUsingGet
   * @summary getAppleUserJwt
   * @request GET:/api/v2/integration/auth/getAppleUserJwt
   * @secure
   */
  getAppleUserJwtUsingGet = (
    query: {
      /** redirect URI который which used for getting auth code */
      appleKid: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfString2, void>({
      path: `/api/v2/integration/auth/getAppleUserJwt`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
