/* eslint-disable no-param-reassign */
import {
  useCodeClient,
  type ImplicitFlowSuccessResponse,
  type ImplicitFlowErrorResponse,
} from 'vue3-google-signin';

import { useApi } from '~/restAPI';
import { Integration } from '~/restAPI/Integration';
import { Account } from '~/restAPI/Account';
import type {
  PreFilledRegisterFormResponse,
  RegistrationOptionsResponse,
} from '~/restAPI/data-contracts';

import { AnalyticsEvents } from '~/types/analytics';

interface IAttachedImg {
  name: string,
  size: number,
  base64: string,
}

interface IForm {
  email: string,
  nickname: string,
  subscriptionApprove: boolean,
  agree: boolean,
  base64Img: IAttachedImg | null,
  avatarUrl: string,
}

interface IProps {
  formData: Ref<IForm>
  phoneNumber: Ref<string>
  phoneNumberVerifyJwtToken: Ref<string>
}

enum SocialNetwork {
  Yandex = 'yandex',
  Google = 'google'
}

interface ISocialAuthData {
  registerForm?: PreFilledRegisterFormResponse
  registrationOptions?: RegistrationOptionsResponse
  jwt: string
}

export const useSocialAuthData = () => useState<ISocialAuthData | undefined>('useSocialAuthData', () => undefined);

function useAuthGoogle(data?: IProps) {
  let registerCallback: null | (() => void) = null;

  const { createApiInstance } = useApi();
  const integrationApi = createApiInstance(Integration);
  const accountApi = createApiInstance(Account);

  const googleCode = ref('');
  const googleLoading = ref(false);

  const { $addEvent } = useNuxtApp();

  const { authDialogChapter } = useAccountStore();

  const socialAuthData = useSocialAuthData();

  const { isReady, login: loginGoogle } = useCodeClient({
    onSuccess: loginOrRegister,
    onError: handleOnError,
    scope: 'openid profile email',
  });

  const {
    data: googleUserJwt,
    execute: getGoogleUserJwt,
    error: googleUserJwtError,
  } = useLazyAsyncData(() => integrationApi.getGoogleUserJwtUsingGet({
    redirectUri: window.origin,
  }, {
    headers: {
      'Google-Auth-Code': googleCode.value,
    },
  }).then((res) => res?.data?.data), { immediate: false });

  const {
    execute: googleSocialAuth,
    error: googleSocialAuthError,
  } = useLazyAsyncData(() => accountApi.googleAuthUsingPost({
    googleIdToken: googleUserJwt.value ?? '',
  }).then((res) => res?.data?.data), { immediate: false });

  const {
    data: googleRegisterData,
    execute: googleRegister,
    error: googleRegisterError,
  } = useLazyAsyncData(() => accountApi.googleRegisterUsingPost({
    authInfo: {
      googleIdToken: socialAuthData.value?.jwt ?? '',
      phoneNumberVerifyJwtToken: data?.phoneNumberVerifyJwtToken.value ?? '',
    },
    registerForm: {
      email: data?.formData.value.email ?? '',
      phone: data?.phoneNumber.value ?? '',
      nickname: data?.formData.value.nickname ?? '',
      subscriptionApprove: data?.formData.value.subscriptionApprove,
      avatarBase64: data?.formData.value.base64Img?.base64,
      avatarUrl: data?.formData.value.avatarUrl,
    },
  }).then((res) => res?.data?.data), { immediate: false });

  const {
    data: parsedGoogleData,
    execute: parseGoogleJwt,
    error: parseGoogleJwtError,
  } = useLazyAsyncData(() => accountApi.parseSocialIdTokenToRegisterFormUsingGet({
    socialAuthProvider: 'GOOGLE',
    socialIdToken: googleUserJwt.value ?? '',
  }).then((res) => res?.data?.data), { immediate: false });

  async function loginOrRegister(response: ImplicitFlowSuccessResponse) {
    googleCode.value = response.code;
    googleLoading.value = true;

    await getGoogleUserJwt();

    if (!googleUserJwt.value) {
      googleLoading.value = false;
      return;
    }

    await googleSocialAuth();

    if (googleSocialAuthError.value?.statusCode === 404) {
      await parseGoogleJwt();

      if (parseGoogleJwtError.value) {
        googleLoading.value = false;

        return;
      }

      socialAuthData.value = parsedGoogleData.value ? {
        ...parsedGoogleData.value,
        jwt: googleUserJwt.value,
      } : undefined;

      googleLoading.value = false;

      if (registerCallback) {
        registerCallback();
      }

      return;
    }

    if (googleSocialAuthError.value) {
      console.error(googleSocialAuthError.value);
      googleLoading.value = false;

      return;
    }

    $addEvent(AnalyticsEvents.EnterLogin, {
      chapter: authDialogChapter.value,
    });

    window.location.reload();
  }

  async function handleOnError(errorResponse: ImplicitFlowErrorResponse) {
    console.error('Error: ', errorResponse);
  }

  function loginGoogleWithCallback(callback?: null | (() => void)) {
    if (callback) {
      registerCallback = callback;
    }

    loginGoogle();
  }

  return {
    isReady,
    loginGoogleWithCallback,
    googleUserJwtError,
    googleLoading,
    googleSocialAuthError,
    googleRegisterData,
    googleRegister,
    googleRegisterError,
    parseGoogleJwtError,
  };
}

function useAuthYandex(data?: IProps) {
  let openedWindow: any;
  let registerCallback: null | (() => void) = null;

  const { createApiInstance } = useApi();
  const integrationApi = createApiInstance(Integration);
  const accountApi = createApiInstance(Account);

  const yandexLoading = ref(false);
  const yandexAccessToken = ref('');
  const yandexUserJwtError = ref<Error | null>(null);

  const socialAuthData = useSocialAuthData();

  const osk = useCookie('osk');
  const config = useRuntimeConfig();
  const { $addEvent } = useNuxtApp();
  const { authDialogChapter } = useAccountStore();

  const {
    data: yandexUserJwt,
    execute: getYandexUserJwt,
    error: getYandexUserJwtError,
  } = useLazyAsyncData(() => integrationApi.getYandexUserJwtUsingGet({
    headers: {
      'Yandex-Access-Token': yandexAccessToken.value,
    },
  }).then((res) => res?.data?.data), { immediate: false });

  const { execute: revokeYandexToken } = useLazyAsyncData(() => integrationApi.revokeYandexAccessTokenUsingGet({
    headers: {
      'Yandex-Access-Token': yandexAccessToken.value,
    },
  }).then((res) => res?.data?.data), { immediate: false });

  const {
    data: yandexRegisterData,
    execute: yandexRegister,
    error: yandexRegisterError,
  } = useLazyAsyncData(() => accountApi.yandexRegisterUsingPost({
    authInfo: {
      yandexIdToken: socialAuthData.value?.jwt ?? '',
      phoneNumberVerifyJwtToken: data?.phoneNumberVerifyJwtToken.value,
    },
    registerForm: {
      email: data?.formData.value.email ?? '',
      phone: data?.phoneNumber.value ?? '',
      nickname: data?.formData.value.nickname ?? '',
      subscriptionApprove: data?.formData.value.subscriptionApprove,
      avatarBase64: data?.formData.value.base64Img?.base64,
      avatarUrl: data?.formData.value.avatarUrl,
    },
  }).then((res) => res?.data?.data), { immediate: false });

  const {
    execute: yandexSocialAuth,
    error: yandexSocialAuthError,
  } = useLazyAsyncData(() => accountApi.yandexAuthUsingPost({
    yandexIdToken: yandexUserJwt.value ?? '',
  }).then((res) => res?.data?.data), { immediate: false });

  const {
    data: parsedYandexData,
    execute: parseYandexJwt,
    error: parseYandexJwtError,
  } = useLazyAsyncData(() => accountApi.parseSocialIdTokenToRegisterFormUsingGet({
    socialAuthProvider: 'YANDEX',
    socialIdToken: yandexUserJwt.value ?? '',
  }).then((res) => res?.data?.data), { immediate: false });

  function loginYandex(callback?: null | (() => void)) {
    if (callback) {
      registerCallback = callback;
    }

    openedWindow = window.YaAuthSuggest.openOauthPopup({
      client_id: config.public.YANDEX_CLIENT_ID,
      response_type: 'token',
      redirect_uri: `${window.origin}/ya-auth`,
      device_id: osk.value ?? '',
    });

    window.addEventListener('message', handleYandexMessage);
  }

  function closeYaWindow() {
    if (openedWindow) {
      openedWindow.close();
      openedWindow = undefined;
      window.removeEventListener('message', handleYandexMessage);
    }
  }

  async function handleYandexMessage(e: MessageEvent) {
    const auth = e.data?.auth;

    if (!auth) return;

    yandexAccessToken.value = auth.accessToken ?? '';

    revokeYandexToken();

    if (auth.error) {
      closeYaWindow();
      yandexUserJwtError.value = auth.error;
      console.error(auth.error);

      return;
    }

    if (auth.login) {
      yandexLoading.value = true;

      closeYaWindow();

      $addEvent(AnalyticsEvents.EnterLogin, {
        chapter: authDialogChapter.value,
      });

      window.location.reload();

      return;
    }

    if (auth.data) {
      socialAuthData.value = auth.data;

      closeYaWindow();

      if (registerCallback) {
        registerCallback();
      }
    }
  }

  return {
    yandexAccessToken,
    yandexUserJwtError,
    loginYandex,
    yandexLoading,
    yandexRegister,
    yandexRegisterError,
    yandexRegisterData,
    getYandexUserJwt,
    getYandexUserJwtError,
    yandexUserJwt,
    yandexSocialAuth,
    yandexSocialAuthError,
    parseYandexJwt,
    parsedYandexData,
    parseYandexJwtError,
  };
}

export function useAuthSocial(data?: IProps) {
  const {
    isReady: isGoogleReady, loginGoogleWithCallback,
    googleUserJwtError, googleLoading,
    googleSocialAuthError, googleRegisterData,
    googleRegister, googleRegisterError,
    parseGoogleJwtError,
  } = useAuthGoogle(data);
  const {
    loginYandex, yandexLoading,
    yandexRegister, yandexRegisterError, yandexRegisterData,
    yandexUserJwtError, yandexAccessToken,
    getYandexUserJwt, getYandexUserJwtError, yandexUserJwt,
    yandexSocialAuth, yandexSocialAuthError,
    parseYandexJwt, parsedYandexData, parseYandexJwtError,
  } = useAuthYandex(data);

  const socialNetworkName = useState<SocialNetwork | undefined>('socialNetworkName', () => undefined);

  const socialLoading = computed(() => yandexLoading.value || googleLoading.value);
  const socialError = computed(() =>
    googleUserJwtError.value
    || yandexUserJwtError.value
    || googleSocialAuthError.value);

  const parseJwtSocialError = computed(() => parseGoogleJwtError.value || parseYandexJwtError.value);

  const registerError = computed(() => yandexRegisterError.value || googleRegisterError.value);
  const registerData = computed(() => yandexRegisterData.value || googleRegisterData.value);

  function removeJwtErrors() {
    yandexUserJwtError.value = null;
    googleUserJwtError.value = null;
    googleUserJwtError.value = null;
  }

  function login(name: SocialNetwork, callback?: null | (() => void)) {
    removeJwtErrors();

    socialNetworkName.value = name;

    if (name === SocialNetwork.Yandex) {
      loginYandex(callback);
    }

    if (name === SocialNetwork.Google) {
      loginGoogleWithCallback(callback);
    }
  }

  async function register() {
    yandexRegisterError.value = null;
    googleRegisterError.value = null;

    if (socialNetworkName.value === SocialNetwork.Yandex) {
      await yandexRegister();
    }

    if (socialNetworkName.value === SocialNetwork.Google) {
      await googleRegister();
    }
  }

  return {
    googleSocialAuthError,
    isGoogleReady,
    socialError,
    socialLoading,
    login,
    register,
    registerError,
    registerData,
    SocialNetwork,
    yandexAccessToken,
    getYandexUserJwt,
    getYandexUserJwtError,
    yandexUserJwt,
    yandexSocialAuth,
    yandexSocialAuthError,
    parseYandexJwt,
    parsedYandexData,
    parseJwtSocialError,
  };
}
